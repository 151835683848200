import React from 'react'
import Signup from '../components/sign/signup/Signup'

const SignUp = () => {
  return (
    <div>
      <Signup />
    </div>
  )
}

export default SignUp