import React from 'react'
import Signin from '../components/sign/signin/Signin'

const SignIn = () => {
  return (
    <div>
      <Signin />
    </div>
  )
}

export default SignIn