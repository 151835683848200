import React from 'react'
import Dashboardpage from '../components/dashboard/dashboardpage/Dashboardpage'

const Dashboard = () => {
  return (
    <div>
      <Dashboardpage />
    </div>
  )
}

export default Dashboard