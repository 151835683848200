import React from 'react'
import Recovery from '../components/sign/recovery/Recovery'

const Recoverypg = () => {
  return (
    <div>
        <Recovery />
    </div>
  )
}

export default Recoverypg